import {OpenAPI} from './api';

interface Config {
    environment: string,
    serviceUrl: string;
    ageOfAdulthood: number;
    applicationCompanyOwnerRequiredWhenLegalFormKeyDe: string[];
    applicationCompanyOwnerRequiredWhenLegalFormKeyCh: string[];
    applicationCompanyOwnerRequiredWhenLegalFormKeyBe: string[];
    applicationCompanyOwnerRequiredWhenLegalFormKeyFr: string[];
    applicationCompanyOwnerRequiredWhenLegalFormKeyAt: string[];
    keycloakUrl: string;
    keycloakRealm: string;
    keycloakClientId: string;
    applicationListPageSize: number;
    userListPageSize: number;
    cardDispatchEnabled: boolean;
    pinForWebShopEnabledForLU: boolean;
    pinForWebShopEnabledForCH: boolean;
    projektWeltEnabled: boolean;
}

const currentConfig: Config = {
    environment: 'local',
    serviceUrl: process.env.REACT_APP_SERVICE_URL ?? '',
    ageOfAdulthood: 18,
    applicationCompanyOwnerRequiredWhenLegalFormKeyDe: ['1', '4', '15', '16', '17', '24', '40', '47',],
    applicationCompanyOwnerRequiredWhenLegalFormKeyCh: ['79',],
    applicationCompanyOwnerRequiredWhenLegalFormKeyBe: ['104',],
    applicationCompanyOwnerRequiredWhenLegalFormKeyFr: ['116', '117', '118'],
    applicationCompanyOwnerRequiredWhenLegalFormKeyAt: ['119',],
    keycloakUrl: 'http://localhost:3579/',
    keycloakRealm: 'quarkus',
    keycloakClientId: 'hornbach-service-tool',
    applicationListPageSize: 20,
    userListPageSize: 20,
    cardDispatchEnabled: true,
    pinForWebShopEnabledForLU: true,
    pinForWebShopEnabledForCH: true,
    projektWeltEnabled: true,
};

// @ts-ignore
const localConfig: Config = window.env as Config || {};
const AppConfig: Config = {...currentConfig, ...localConfig};

OpenAPI.BASE = AppConfig.serviceUrl;

export default AppConfig;
